





























































































































































































































import { Component, Vue } from "vue-property-decorator";
import dieta_visualizacionModule from "@/store/modules/visualizacion_dieta/dieta_visualizacion-module";
import { UtilsString } from "@/utils/utils-string";

@Component({
  components: {}
})
export default class EstadisticasVisualizacion extends Vue {
  public get datos() {
    return dieta_visualizacionModule.estadistica;
  }

  public stilo(diff: string, dato: string) {
    if (
      UtilsString.ValueOf(diff)
        .substring(0, 1)
        .includes("-")
    ) {
      return '<span class="red--text">' + dato + "</span>";
    }
    return;
  }
}
